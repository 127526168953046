<template>
  <header class="header">
    <b-navbar type="dark" class="navbar" variant="dark">
      <b-container>
        <b-navbar-brand href="#">MovieDB</b-navbar-brand>
        <b-nav-form>
          <!-- 83-1 -->
          <!-- 84 -->
          <b-form-input
            class="mr-sm-2 search-input"
            placeholder="Search"
            v-model="searchValue"
            debounce="500"
          ></b-form-input>
          >
        </b-nav-form>
      </b-container>
    </b-navbar>
  </header>
</template>

<script>
// 86
import { mapActions } from "vuex";

export default {
  name: "Header",
  // 83
  data: () => ({
    searchValue: "",
  }),
  watch: {
    // 83-2
    searchValue: "onSearchValueChange",
  },
  methods: {
    // 86-1
    // 88
    // 92
    ...mapActions("movies", [
      "searchMovies",
      "fetchMovies",
      "toggleSearchState",
    ]),
    // 83-3
    onSearchValueChange(val) {
      // console.log(val);
      // 88-1
      // 92-1
      if (val) {
        this.searchMovies(val);
        this.toggleSearchState(true);
      } else {
        this.fetchMovies();
        this.toggleSearchState(false);
      }
    },
  },
};
</script>

<style scoped>
/* 82 */
.header {
  margin-bottom: 30px;
}
.navbar {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.search-input {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.6);
}
.search-input:focus {
  box-shadow: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(0, 0, 0, 0.6);
}
</style>

export default {
  // 21
  MOVIES: "MOVIES",
  // 59
  CURRENT_PAGE: "CURRENT_PAGE",
  // 67
  TOGGLE_LOADER: "TOGGLE_LOADER",
  // 78
  REMOVE_MOVIE: "REMOVE_MOVIE",
  // 90
  TOGGLE_SEARCH: "TOGGLE_SEARCH",
  // 95
  SHOW_TOAST_NOTIFY: "SHOW_TOAST_NOTIFY",
};
